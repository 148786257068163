<!--
 * @Descripttion: 回收站
 * @version: 
 * @Author: LCQ
 * @Date: 2021-03-17 11:30:57
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-13 18:24:08
-->
<template>
  <div class="recycle-bin">
    <div class="content">
      <div class="header">
        <div class="title">问卷列表</div>
         <a-input-search
          class="search"
          v-model:value="searchParams.titleOrDateNumId"
          placeholder="请输入问卷名称/ID"
          @search="getList(true)"
        />
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="left ellipsis" :title="item.title">{{item.title}}</div>
          <div class="center">
            <div>ID: {{item.dateNumId}}</div>
            <div>
              答卷：&nbsp;&nbsp;{{item.validQuestionnaireNum}}
              /&nbsp;{{(item.validQuestionnaireNum || 0) + (item.inValidQuestionnaireNum || 0)}} 
            </div>
            <div>{{item.modifyTime}}</div>
          </div>
          <div class="right">
            <div @click="handleDelete(item)"><span class="iconfont">&#xe609;</span></div>
            <div @click="handleRecover(item)"><span class="iconfont recycle">&#xe614;</span></div>
          </div>
        </div>
      </div>
      <div class="pagination-wrapper">
        <Pager
          class="pager" 
          :current="pagination.current"
          :total="pagination.total"
          :size="pagination.size"
          @onPageChange="handlePaginationChange"
          @onShowSizeChange="showSizeChange"
        />
      </div>
    </div>
  </div>
  
</template>

<script>
import { getRecycleList, handleRecycleApi } from '@/api/recycleBin'
import Pager from '@/components/common/Pager'
// import { reactive, watch, toRefs, ref, getCurrentInstance } from 'vue'


export default {
  name: 'RecycleBin',
  components: {
    Pager
  },
  setup() {
  },
  data () {
    return {
      list: [],
      searchParams: {
        titleOrDateNumId: '',
        ifDelete: true,
      },
      pagination: {
        pageSize: 10,
        total: 0,
        current: 0,
        size: 10,
      },
    }
  },
  methods: {
    showSizeChange(current, size) {
      console.log("size", size);
      this.pagination.current = 0;
      this.pagination.pageSize = size
      this.getList()
    },
    handlePaginationChange(current) {
      console.log("current", current);
      this.pagination.current = current;
      this.getList()
    },
    async handleDelete(item) {
      let data = {
        questionnaireProtoId: item.id,
        option: "COMPLETE_DELETE"
      }
      const res = await handleRecycleApi(data)
      if (res.data.code == 0) {
        this.$message.success('彻底删除成功')
        this.getList()
      } else {
        this.$message.error(res.data.msg)
      }
    },
    async handleRecover(item) {
      let data = {
        questionnaireProtoId: item.id,
        option: "RECOVERY"
      }
      const res = await handleRecycleApi(data)
      if (res.data.code == 0) {
        this.$message.success('恢复成功')
        this.getList()
      } else {
        this.$message.error(res.data.msg)
      }
    },
    async getList(isSearch, isReset) { // 获取数据
      let params = {
        size: this.pagination.pageSize,
        page: this.pagination.current,
      }
      params = Object.assign({}, this.searchParams, params)
      const res = await getRecycleList(params)
      console.log('res', res)
      if (res.data.code == 0) {
        this.pagination.total = Number(res.data.data.totalElements)
        // this.pagination.total = 100
        this.list = res.data.data.content
        isSearch && this.$message.success('搜索成功')
        isReset && this.$message.success('重置成功')
      } else {
        this.$message.error(res.data.msg)
      }
    },
  },
  created () {
    // this.onSearch()
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.recycle-bin {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
  .content {
    width: 1300px;
    margin: 20px auto;
    background: #fff;
    border-radius: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 60px 50px 52px 50px;
      > div:nth-child(1) {
        font-size: 26px;
        font-weight: bold;
      }
      .search {
        width: 400px;
        height: 46px;
        line-height: 46px;
        border-radius: 23px;
        border: 1px solid #E1E1E8;
        padding: 10px;
        ::v-deep(input) {
          margin-left: 5px;
          font-size: 16px;
        }
        ::v-deep(.ant-input-suffix) {
          svg {
            width: 27px;
            height: 27px;
          }
        }
      }
    }

    .list {
      width: 100%;
      .item  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 132px;
        margin: 0px 50px 8px 50px;
        border-bottom: 1px solid #e6e6e6;
        background-color: #FAFAFB;
        font-weight: 100;
        .left {
          width: 464px;
          font-size: 20px;
          white-space: nowrap;
          margin-left: 16px;
        }
        .center {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          font-size: 20px;
          color: #7F7F88;
          > div:nth-child(1) {
            border: 1px solid #E1E1E8;
            padding: 0 .3rem;
            background-color: #fff;
          }
          > div:nth-child(2) {
            display: table-cell;
            margin: 0 1rem;
            vertical-align: bottom;
            white-space: nowrap;
          }
        }
        .right {
          display: flex;
          .iconfont {
            font-size: 28px;
          color: #00A0E9;
          }
          .recycle {
            transform: rotate(90deg);
          }
          > div {
            margin-right: 1.5rem;
            cursor: pointer;
          }
        }
      }
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
.pager {
  margin-right: 50px;
  margin-top: 16px;
  padding-bottom: 50px;
}
</style>