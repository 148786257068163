<!--
 * @Description: antd页码通用组件
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-16 14:08:41
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-06 18:32:33
-->
<template>
  <a-pagination
    class="pagination"
    size="small"
    v-model:current="current"
    :page-size-options="pageSizeOptions"
    :total="total"
    show-quick-jumper
    show-size-changer
    v-model:pageSize="size"
    @change="onChange"
    @showSizeChange="onShowSizeChange"
  >
    <template #buildOptionText="props">
      <span v-if="props.value !== '500000000'">{{ props.value }}条/页</span>
      <span v-else>全部</span>
    </template>
  </a-pagination>
</template>
<script>
import { reactive, ref, toRefs, watch, watchEffect } from 'vue';

/**
 * 监听props实时获取分页信息
 */
const watchPropsEffect = (props) => {
  const pagerInfo = reactive({
    current: props.current,
    total: props.total,
    size: props.size
  })
  watchEffect(() => {
     pagerInfo.current = Number(props.current) + 1 // antdv，第一页的index是1
     pagerInfo.total = Number(props.total)
     pagerInfo.size = Number(props.size)
  })
  const { current, total, size } = toRefs(pagerInfo)
  return { current, total, size }
}

/**
 * 页码变化监听
 */
const onPageChangeEffect = (emit) => {
  // 监听每页条数
  const onShowSizeChange = (pageNumber, PageSize) => {
    emit('onPageChange', pageNumber - 1, PageSize)
    emit('onShowSizeChange', pageNumber - 1, PageSize)
  }
  // 监听页码
  const onChange = (pageNumber, PageSize) => {
    emit('onPageChange', pageNumber - 1, PageSize)
  }
  return { onShowSizeChange, onChange}
}

export default({
  name: 'Pager',
  props: [
    'current', // 当前页数
    'total', // 数据总数
    'size', // 每页多少条数据
    'shape', // 分页显示的外观形状，可传'small'(可不传)
  ],
  emits: [
    'onPageChange',
    'onShowSizeChange'
  ],
  /**
   * 程序入口
   */
  setup(props, { emit }) {
    const pageSizeOptions = ref(['5', '10', '20', '30', '40', '500000000']);
    const { current, total, size } = watchPropsEffect(props)
    const { onShowSizeChange, onChange } = onPageChangeEffect(emit)

    return {
      pageSizeOptions,
      current,
      size,
      total,
      onShowSizeChange,
      onChange
    };
  },
});
</script>

<style scoped lang="less">
.pagination {
  ::v-deep(.ant-pagination-options) {
    .ant-select-selector {
      width: 129px;
      height: 41px !important;
      border-radius: 0px !important;
      .ant-select-selection-item {
        text-align: left;
        line-height: 41px !important;
        margin-left: 5px;
      }
    }
    .ant-pagination-options-quick-jumper {
      height: 41px !important;
      line-height: 41px !important;
      input {
        width: 64px;
        height: 41px;
        line-height: 41px;
        border-radius: 0px;
      }
    }
  }
}
:global(.ant-pagination-item) {
  font-weight: 100;
  color: #2D2D33;
  border: none;
}
:global(.ant-pagination-item-active) {
  font-weight: 500;
  background: #00A0E9;
}
:global(.ant-pagination-item-active a) {
  color: #fff!important;
}
:global(.ant-pagination-prev .ant-pagination-item-link) {
  border: none;
}
:global(.ant-pagination-next .ant-pagination-item-link) {
  border: none;
}
:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 10px;
}
// :global(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
//     border-color: #f59a23;
// }
:global(.ant-pagination-options-quick-jumper input) {
  border-radius: 10px;
}

</style>
