/*
 * @Descripttion: 回收站api
 * @version: 
 * @Author: LCQ
 * @Date: 2021-03-17 16:38:41
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-13 15:45:33
 */
import service from '@/utils/request.js'

export function getRecycleList(params) {
  return service.request({
    url: '/mng/questionnaire-proto?sort=modifyTime,desc',
    method: 'get',
    params
  })
}

export function handleRecycleApi(data) { // 操作问卷的api
  return service.request({
    url: '/mng/file-hierarchy/questionnaire-proto',
    method: 'post',
    data
  })
}